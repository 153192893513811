import React from 'react';
import { makeStyles } from '@material-ui/core';
import { defineMessages, useIntl } from 'react-intl';

import Culture from '../assets/photos/Culture.jpg';
import Tradition from '../assets/photos/Tradition.jpg';
import Language from '../assets/photos/Language.jpg';
import Religion from '../assets/photos/Religion.jpg';

const useStyles = makeStyles({
  tile: {
    minWidth: '100%',
    maxWidth: '100%',
    textAlign: 'justify',
  },
  tileHeader: {
    fontFamily: 'Raleway',
    fontSize: '2em',
    fontWeight: 'bold',
    padding: '3%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      paddingBottom: '10%',
    },
  },
  tileBody: {
    fontFamily: 'Open Sans',
    lineHeight: 1.8,
    backgroundColor: '#f8f5f2',
    borderRadius: '5px',
    'box-shadow': '0 0 0 30px #f8f5f2',
  },
  imgBox: {
    paddingTop: '3%',
  },
  img: {
    maxWidth: 'calc(100% + 60px)',
    borderRadius: '30px',
    marginLeft: '-30px',
  },
});

//Workaround to make messages visible to babel
const headers = defineMessages({
  Culture: { id: 'Lemkos.Tile.Culture.Header' },
  Tradition: { id: 'Lemkos.Tile.Tradition.Header' },
  Religion: { id: 'Lemkos.Tile.Religion.Header' },
  Language: { id: 'Lemkos.Tile.Language.Header' },
});

//Workaround to make messages visible to babel
const bodies = defineMessages({
  Culture: { id: 'Lemkos.Tile.Culture.Body' },
  Tradition: { id: 'Lemkos.Tile.Tradition.Body' },
  Religion: { id: 'Lemkos.Tile.Religion.Body' },
  Language: { id: 'Lemkos.Tile.Language.Body' },
});

const formatter = {
  i: (...msg) => <i>{msg}</i>,
  p: (...msg) => <p>{msg}</p>,
};

const photos = {
  Culture: { image: Culture, text: 'Culture' },
  Tradition: { image: Tradition, text: 'Tradition' },
  Language: { image: Language, text: 'Language' },
  Religion: { image: Religion, text: 'Religion' },
};

export const Tile = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.tile}>
      <div className={classes.tileHeader}>{intl.formatMessage(headers[props.choice])}</div>
      <div className={classes.tileBody}>{intl.formatMessage(bodies[props.choice], formatter)}</div>
      <div className={classes.imgBox}>
        <img src={photos[props.choice].image} alt="tilePhoto" className={classes.img} />
      </div>
    </div>
  );
};
export default Tile;
