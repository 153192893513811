import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import mainview from '../assets/photos/MainView.jpg';
import mainview_reversed from '../assets/photos/MainViewReversedAspectRatio.jpg';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexShrink: '1',
    textAlign: 'center',
    marginTop: '0',
  },
  img: {
    height: '100vh',
    width: '100%',
  },
});

const Home = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
  });

  useEffect(() => {
    const setResponsiveness = () => {
      return ( window.innerWidth < 1050 & window.innerWidth < window.innerHeight )
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  return (
    <div className={classes.wrapper}>
      <img src={state.mobileView ? mainview_reversed : mainview} alt="main" className={classes.img} />
    </div>
  );
};

export default Home;
