import React from 'react';
import { IntlContext } from '../utils/IntlContextProvider';

const LanguageBar = () => {
  const { switchToEnglish, switchToPolish, switchToLemko } = React.useContext(IntlContext);
  return (
    <div>
      <button onClick={switchToLemko} color="red">
        LEM
      </button>
      <button onClick={switchToEnglish} color="red">
        EN
      </button>
      <button onClick={switchToPolish} color="red">
        PL
      </button>
    </div>
  );
};

export default LanguageBar;
