import { defineMessages } from 'react-intl';

import m_kiec from '../../assets/photos/quotes/m_kiec.jpg';
import p_trochanowski from '../../assets/photos/quotes/p_trochanowski.jpg';
import odf from '../../assets/photos/quotes/odf.jpg';
import b_gocz from '../../assets/photos/quotes/b_gocz.jpg';
import j_starzynski from '../../assets/photos/quotes/j_starzynski.jpg';
import w_graban from '../../assets/photos/quotes/w_graban.jpg';
import n_malecka from '../../assets/photos/quotes/n_malecka.jpg';
import a_barna from '../../assets/photos/quotes/a_barna.jpg';
import n_pelak from '../../assets/photos/quotes/n_pelak.jpg';
import m_szymko from '../../assets/photos/quotes/m_szymko.jpg';

const names = defineMessages({
  MKiec: { id: 'Quotes.Names.MKiec' },
  PTrochanowski: { id: 'Quotes.Names.PTrochanowski' },
  ODF: { id: 'Quotes.Names.ODF' },
  BGocz: { id: 'Quotes.Names.BGocz' },
  JStarzynski: { id: 'Quotes.Names.JStarzynski' },
  WGraban: { id: 'Quotes.Names.WGraban' },
  NMalecka: { id: 'Quotes.Names.NMalecka' },
  ABarna: { id: 'Quotes.Names.ABarna'},
  NPelak: { id: 'Quotes.Names.NPelak' },
  MSzymko: { id: 'Quotes.Names.MSzymko'},
});

const quotes = defineMessages({
  MKiec: { id: 'Quotes.Quotes.MKiec' },
  PTrochanowski: { id: 'Quotes.Quotes.PTrochanowski' },
  ODF: { id: 'Quotes.Quotes.ODF' },
  BGocz: { id: 'Quotes.Quotes.BGocz' },
  JStarzynski: { id: 'Quotes.Quotes.JStarzynski' },
  WGraban: { id: 'Quotes.Quotes.WGraban' },
  NMalecka: { id: 'Quotes.Quotes.NMalecka' },
  ABarna: { id: 'Quotes.Quotes.ABarna'},
  NPelak: { id: 'Quotes.Quotes.NPelak' },
  MSzymko: { id: 'Quotes.Quotes.MSzymko'},
});

const abouts = defineMessages({
  MKiec: { id: 'Quotes.About.MKiec' },
  PTrochanowski: { id: 'Quotes.About.PTrochanowski' },
  ODF: { id: 'Quotes.About.ODF' },
  BGocz: { id: 'Quotes.About.BGocz' },
  JStarzynski: { id: 'Quotes.About.JStarzynski' },
  WGraban: { id: 'Quotes.About.WGraban' },
  NMalecka: { id: 'Quotes.About.NMalecka' },
  ABarna: { id: 'Quotes.About.ABarna'},
  NPelak: { id: 'Quotes.About.NPelak' },
  MSzymko: { id: 'Quotes.About.MSzymko'},
});

export const carouselCards = [
  // {
  //   img: m_kiec,
  //   alt: 'Margarita Kiec',
  //   quote: quotes.MKiec,
  //   name: names.MKiec,
  //   subName: abouts.MKiec,
  // },
  {
    img: p_trochanowski,
    alt: 'Piotr Trochanowski',
    quote: quotes.PTrochanowski,
    name: names.PTrochanowski,
    subName: abouts.PTrochanowski,
  },
  {
    img: odf,
    alt: 'Olena Duć-Fajfer',
    quote: quotes.ODF,
    name: names.ODF,
    subName: abouts.ODF,
  },
  {
    img: b_gocz,
    alt: 'Bohdan Gocz',
    quote: quotes.BGocz,
    name: names.BGocz,
    subName: abouts.BGocz,
  },
  {
    img: j_starzynski,
    alt: 'Jerzy Starzyński',
    quote: quotes.JStarzynski,
    name: names.JStarzynski,
    subName: abouts.JStarzynski,
  },
  {
    img: w_graban,
    alt: 'Władysław Graban',
    quote: quotes.WGraban,
    name: names.WGraban,
    subName: abouts.WGraban,
  },
  {
    img: n_malecka,
    alt: 'Natalia Małecka',
    quote: quotes.NMalecka,
    name: names.NMalecka,
    subName: abouts.NMalecka,
  },
  {
    img: a_barna,
    alt: 'Adam Barna',
    quote: quotes.ABarna,
    name: names.ABarna,
    subName: abouts.ABarna,
  },
  {
    img: n_pelak,
    alt: 'Natalia Pelak',
    quote: quotes.NPelak,
    name: names.NPelak,
    subName: abouts.NPelak,
  },
  {
    img: m_szymko,
    alt: 'Michał Szymko',
    quote: quotes.MSzymko,
    name: names.MSzymko,
    subName: abouts.MSzymko,
  },
];
