import { makeStyles } from '@material-ui/core';
import React from 'react';
import kyczerka from '../assets/photos/kyczerka.jpg';

const useStyles = makeStyles({
  img: {
    maxHeight: '100%',
    width: '100%',
  },
});

const Kids = () => {
  const classes = useStyles();
  return <img src={kyczerka} alt="children" className={classes.img} />;
};

export default Kids;
