import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import Tile from '../../components/Tile';
import Subpage from '../../components/Subpage';
import LemkoTimeline from '../../components/Timeline';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    paddingTop: '7%',
  },
  content: {
    padding: '1% 5% 1% 10%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      padding: '1% 5% 1% 5%',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
  },
  header: {
    fontSize: '3em',
    letterSpacing: '8px',
    fontWeight: 'bold',
    marginBottom: '5vh',
    fontFamily: 'Raleway',
  },
  text: {
    fontFamily: 'Open Sans',
    lineHeight: 1.8,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column',
    },
  },
  left: {
    width: '70%',
    textAlign: 'left',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
    },
  },
  right: {
    width: '30%',
    paddingLeft: '5%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingLeft: '0%',
    },
  },
  tiles: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

const choices = {
  culture: 'Culture',
  tradition: 'Tradition',
  religion: 'Religion',
  language: 'Language',
};

function MainParas() {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.text}>
        <FormattedMessage id="Lemkos.Para.War" />
      </p>
      <p className={classes.text}>
        <FormattedMessage id="Lemkos.Para.Now" />
      </p>
      <p className={classes.text}>
        <FormattedMessage id="Lemkos.Para.Rusyns" />
      </p>
      <p className={classes.text}>
        <FormattedMessage id="Lemkos.Para.Headcount" />
      </p>
      <p className={classes.text}>
        <FormattedMessage id="Lemkos.Para.JaLemko" />
      </p>
    </div>
  );
}

function Tiles() {
  const classes = useStyles();

  return (
    <div className={classes.tiles}>
      {Object.values(choices).map((choice) => (
        <Tile key={choice} choice={choice} />
      ))}
    </div>
  );
}

const Lemkos = () => {
  const classes = useStyles();

  return (
    <Subpage>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.textWrapper}>
            <h3 className={classes.header}>
              <FormattedMessage id="Lemkos.Header" />
            </h3>
          </div>
          <div className={classes.row}>
            <div className={classes.left}>
              <div className={classes.textWrapper}>
                <MainParas />
                <Tiles />
              </div>
            </div>
            <div className={classes.right}>
              <LemkoTimeline />
            </div>
          </div>
        </div>
      </div>
    </Subpage>
  );
};

export default React.memo(React.forwardRef(Lemkos));
