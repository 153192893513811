import React from 'react';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import Subpage from '../components/Subpage';

const styles = () => ({
  outer: {
    backgroundColor: '#078080',
    color: 'white',
    minHeight: '100vh',
    width: '100%',
    paddingTop: '10%',
    paddingBottom: '5%',
  },
  content: {
    padding: '1% 10% 1% 10%',
    lineHeight: '1.5em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
  },
  headingDivider: {
    backgroundColor: 'white',
    minHeight: '3px',
    maxWidth: '10%',
    marginTop: '10%',
    marginBottom: '10%',
  },
  subtitlesDivider: {
    backgroundColor: 'white',
    minHeight: '2px',
  },
  flexParagraph: {
    display: 'flex',
    marginBottom: '2%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column',
    },
  },
  heading: {
    width: '50%',
    marginRight: '25%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
    },
  },
  title: {
    fontSize: '3em',
    fontWeight: 'bold',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '2em',
    },
  },
  subtitles: {
    fontSize: '1.5em',
    width: '25%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      marginTop: '5%',
      marginBottom: '5%',
    },
  },
  subtitle: {
    marginTop: '10%',
    marginBottom: '10%',
    fontWeight: 'bold',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      marginTop: '5%',
      marginBottom: '5%',
    },
  },
  shortenedParagraph: {
    fontSize: '1.25em',
    lineHeight: 1.8,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      textAlign: 'justify',
      paddingRight: '0%',
      paddingBottom: '2%',
    },
  },
  paragraph: {
    paddingTop: '2%',
    textAlign: 'justify',
    '& li': {
      paddingBottom: '2%',
    },
  },
});

const LawNote = (props) => {
  const { classes } = props;

  return (
    <Subpage>
      <div className={classes.outer}>
        <div className={classes.content}>
          <div className={classes.flexParagraph}>
            <div className={classes.heading}>
              <div className={classes.title}>
                <FormattedMessage id="LawNote.Heading.Headline" />
                <Divider className={classes.headingDivider}></Divider>
              </div>
              <div className={classes.shortenedParagraph}>
                <FormattedMessage id="LawNote.Heading.Headparagraph" />
              </div>
            </div>
            <div className={classes.subtitles}>
              <Divider className={classes.subtitlesDivider}></Divider>
              <div className={classes.subtitle}>
                <FormattedMessage id="LawNote.Heading.ConstitutionText" />
              </div>
              <Divider className={classes.subtitlesDivider}></Divider>
              <div className={classes.subtitle}>
                <FormattedMessage id="LawNote.Heading.ActText" />
              </div>
              <Divider className={classes.subtitlesDivider}></Divider>
            </div>
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage id="LawNote.Para.Rights" />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage id="LawNote.Para.Sources" />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage
              style={{ lineHeight: '20%' }}
              id="LawNote.Para.Acts"
              values={{
                ul: (...msg) => <ul>{msg}</ul>,
                li: (...msg) => <li>{msg}</li>,
                b: (...msg) => <b>{msg}</b>,
              }}
            />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage id="LawNote.Para.Definition" />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage id="LawNote.Para.Minority" />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage
              style={{ lineHeight: '20%' }}
              id="LawNote.Para.Conditions"
              values={{
                ul: (...msg) => <ul>{msg}</ul>,
                li: (...msg) => <li>{msg}</li>,
                b: (...msg) => <b>{msg}</b>,
              }}
            />
          </div>
          <div className={classes.paragraph}>
            <FormattedMessage id="LawNote.Para.Stateless" />
          </div>
        </div>
      </div>
    </Subpage>
  );
};

export default withStyles(styles)(LawNote);
