import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useIntl, defineMessages } from 'react-intl';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import TimelineElement from './TimelineElement';

const useStyles = makeStyles({
  main: {
    width: '90%',
  },
});

const messages = defineMessages({
  Ciril: { id: 'Timeline.Ciril' },
  BaptismRus: { id: 'Timeline.BaptismRus' },
  RusynSettlement: { id: 'Timeline.RusynSettlement' },
  BrestUnion: { id: 'Timeline.BrestUnion' },
  Prosvita: { id: 'Timeline.Prosvita' },
  Kaczkowski: { id: 'Timeline.Kaczkowski' },
  SandeckaBursa: { id: 'Timeline.SandeckaBursa' },
  RuskaBursa: { id: 'Timeline.RuskaBursa' },
  Talerhof: { id: 'Timeline.Talerhof' },
  Komancza: { id: 'Timeline.Komancza' },
  Florynka: { id: 'Timeline.Florynka' },
  Schism: { id: 'Timeline.Schism' },
  AAL: { id: 'Timeline.AAL' },
  Deportation: { id: 'Timeline.Deportation' },
  Jaworzno: { id: 'Timeline.Jaworzno' },
  OperationVistula: { id: 'Timeline.OperationVistula' },
  Return: { id: 'Timeline.Return' },
  Zyndranowa: { id: 'Timeline.Zyndranowa' },
  Lemkowyna: { id: 'Timeline.Lemkowyna' },
  Watra: { id: 'Timeline.Watra' },
  LemkoAssociation: { id: 'Timeline.LemkoAssociation' },
  LemkoUnion: { id: 'Timeline.LemkoUnion' },
  Kyczera: { id: 'Timeline.Kyczera' },
  LemkoTower: { id: 'Timeline.LemkoTower' },
  Standarisation: { id: 'Timeline.Standarisation' },
  Philology: { id: 'Timeline.Philology' },
  MinoritiesAct: { id: 'Timeline.MinoritiesAct' },
  Bilingual: { id: 'Timeline.Bilingual' },
  LemFm: { id: 'Timeline.LemFm' },
});

export default function LemkoTimeline() {
  const classes = useStyles();
  const intl = useIntl();

  const sections = [
    { date: '862', child: intl.formatMessage(messages.Ciril) },
    { date: '988', child: intl.formatMessage(messages.BaptismRus) },
    { date: '1413', child: intl.formatMessage(messages.RusynSettlement) },
    { date: '1596', child: intl.formatMessage(messages.BrestUnion) },
    { date: '1868', child: intl.formatMessage(messages.Prosvita) },
    { date: '1874', child: intl.formatMessage(messages.Kaczkowski) },
    { date: '1898', child: intl.formatMessage(messages.SandeckaBursa) },
    { date: '1908', child: intl.formatMessage(messages.RuskaBursa) },
    { date: '1914', child: intl.formatMessage(messages.Talerhof) },
    { date: '1918', child: intl.formatMessage(messages.Komancza) },
    { date: '1918', child: intl.formatMessage(messages.Florynka) },
    { date: '1926', child: intl.formatMessage(messages.Schism) },
    { date: '1934', child: intl.formatMessage(messages.AAL) },
    { date: '1944', child: intl.formatMessage(messages.Deportation) },
    { date: '1945', child: intl.formatMessage(messages.Jaworzno) },
    { date: '1947', child: intl.formatMessage(messages.OperationVistula) },
    { date: '1956', child: intl.formatMessage(messages.Return) },
    { date: '1968', child: intl.formatMessage(messages.Zyndranowa) },
    { date: '1969', child: intl.formatMessage(messages.Lemkowyna) },
    { date: '1983', child: intl.formatMessage(messages.Watra) },
    { date: '1989', child: intl.formatMessage(messages.LemkoAssociation) },
    { date: '1989', child: intl.formatMessage(messages.LemkoUnion) },
    { date: '1991', child: intl.formatMessage(messages.Kyczera) },
    { date: '1992', child: intl.formatMessage(messages.LemkoTower) },
    { date: '2000', child: intl.formatMessage(messages.Standarisation) },
    { date: '2001', child: intl.formatMessage(messages.Philology) },
    { date: '2005', child: intl.formatMessage(messages.MinoritiesAct) },
    { date: '2009', child: intl.formatMessage(messages.Bilingual) },
    { date: '2011', child: intl.formatMessage(messages.LemFm) },
  ];

  return (
    <VerticalTimeline animate="True" layout="2-columns-right" className={classes.main}>
      {sections.map((section, index) => (
        <TimelineElement key={index} date={section.date}>
          {section.child}
        </TimelineElement>
      ))}
    </VerticalTimeline>
  );
}
