import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  innerWrapper: {
    fontSize: '1.5em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: '2%',
    textDecoration: 'none',
  },
  '@keyframes fadeInEffect': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
});

const FillIn = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <FavoriteBorderIcon style={{ fontSize: '6em', color: '#078080' }} />
        <h1>
          <FormattedMessage id="Common.YourMove" />
        </h1>
        <div className={classes.button}>
          <Button variant="contained" color="primary" href="https://nsp2021.spis.gov.pl/">
            <FormattedMessage id="Common.FillIn" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FillIn;
