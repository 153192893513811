import React from 'react';
import { makeStyles } from '@material-ui/core';
import Carousel from './Carousel';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  wrapper: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    padding: '1% 5% 1% 5%',
  },
  carouselWrapper: {
    maxWidth: '100%',
    height: 'auto',
    minHeight: '70vh',
  },
  button: {
    paddingBottom: '2%',
  },
});

const Census = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h1>
          <FormattedMessage id="Census.Header" />
        </h1>
        <p className={classes.subText}>
          <FormattedMessage id="Census.Description" />
        </p>
      </div>
      <div className={classes.carouselWrapper}>
        <Carousel />
      </div>
    </div>
  );
};

export default Census;
