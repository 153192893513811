import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Subpage from '../components/Subpage';

import daria from '../assets/photos/us/daria.jpg';
import ola from '../assets/photos/us/ola.jpg';
import mateusz from '../assets/photos/us/mateusz.jpg';
import asia from '../assets/photos/us/asia.jpg';
import seweryn from '../assets/photos/us/seweryn.jpg';
import pawel from '../assets/photos/us/pawel.jpg';
import petro from '../assets/photos/us/petro.jpg';
import marcin from '../assets/photos/us/marcin.jpg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: '#feefe8',
    paddingTop: '7%',
  },
  content: {
    padding: '1% 10% 1% 10%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
  },
  intro: {
    marginBottom: '5%',
    textAlign: 'justify',
  },
  title: {
    fontSize: '3em',
    marginBottom: '10vh',
    fontFamily: 'Raleway, bold',
  },
  header: {
    fontFamily: 'Open Sans',
    fontSize: '1.5em',
    lineHeight: 1.8,
    color: '#f45d48',
    marginBottom: '5vh',
    textTransform: 'uppercase',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      marginBottom: '0',
    },
  },
  text: {
    fontFamily: 'Open Sans',
    fontSize: '1em',
    lineHeight: 1.8,
  },
  row: {
    flexShrink: '1',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '5%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column',
    },
  },
  left: {
    width: '50%',
    textAlign: 'justify',
    marginRight: '5%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
    },
  },
  right: {
    width: '50%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingBottom: '10%',
    },
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  img: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '500px',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      maxWidth: '100%',
    },
  },
});

const MeetUs = () => {
  const classes = useStyles();

  return (
    <Subpage>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.intro}>
            <link
              href="https://fonts.googleapis.com/css2?family=Oswald&display=swap"
              rel="stylesheet"
            ></link>
            <h1 className={classes.title}>
              <FormattedMessage id="MeetUs.Header" />
            </h1>
            <h2 className={classes.text}>
              <FormattedMessage id="MeetUs.Description" />
            </h2>
          </div>
          <div className={classes.people}>
            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.MateuszStafiniak" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.MateuszStafiniak" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={mateusz} alt="Mateusz Stafiniak" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.PiotrHerbut" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.PiotrHerbut" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={petro} alt="Piotr Herbut" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.JoannaSpiak" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.JoannaSpiak" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={asia} alt="Joanna Spiak" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.SewerynKosowski" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.SewerynKosowski" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={seweryn} alt="Seweryn Kosowski" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.DariaKuziak" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.DariaKuziak" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={daria} alt="Daria Kuziak" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.PawelStarzynski" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.PawelStarzynski" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={pawel} alt="Pawel Starzynski" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.OlgaStarzynska" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.OlgaStarzynska" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={ola} alt="Olga Starzyńska" className={classes.img} />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classes.left}>
                <div className={classes.header}>
                  <FormattedMessage id="MeetUs.Names.MarcinWojciechowski" />
                </div>
                <div className={classes.text}>
                  <FormattedMessage id="MeetUs.About.MarcinWojciechowski" />
                </div>
              </div>
              <div className={classes.right}>
                <img src={marcin} alt="Marcin Wojciechowski" className={classes.img} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Subpage>
  );
};

MeetUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MeetUs;
