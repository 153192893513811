import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Divider from '@material-ui/core/Divider';
import pc from '../../assets/photos/census-pc.jpg';
import Button from '@material-ui/core/Button';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Subpage from '../../components/Subpage';

const useStyles = makeStyles({
  outer: {
    backgroundColor: 'white',
    color: '#078080',
    minHeight: '100vh',
    paddingTop: '10%',
  },
  content: {
    padding: '1% 10% 1% 10%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
  },
  headingDivider: {
    backgroundColor: '#078080',
    minHeight: '3px',
    maxWidth: '10%',
    marginTop: '10%',
    marginBottom: '10%',
  },
  subtitlesDivider: {
    backgroundColor: '#078080',
    minHeight: '2px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5%',
    paddingBottom: '5%',
    textAlign: 'justify',
  },
  flexParagraph: {
    display: 'flex',
    paddingBottom: '3%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column',
      paddingBottom: '5%',
    },
  },
  flexReverse: {
    display: 'flex',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column-reverse',
    },
  },
  heading: {
    width: '70%',
    paddingLeft: '5%',
    paddingRight: '15%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingLeft: '0%',
      paddingRight: '0%',
    },
  },
  title: {
    fontSize: '3em',
    fontWeight: '900',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '2em',
    },
  },
  howToHeading: {
    width: '50%',
    margin: 'auto',
    textAlign: 'center',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingTop: '5%',
      paddingBottom: '10%',
    },
  },
  howTo: {
    fontSize: '3em',
    fontWeight: 'bold',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '2em',
    },
  },
  subtitles: {
    fontSize: '1.5em',
    width: '30%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      marginTop: '5%',
      marginBottom: '5%',
    },
  },
  subtitle: {
    marginTop: '10%',
    marginBottom: '10%',
    fontWeight: 'bold',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      marginTop: '5%',
      marginBottom: '5%',
    },
  },
  video: {
    objectFit: 'contain',
    flex: '1',
    width: '50%',
    display: 'flex',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
    },
  },
  picture: {
    width: '50%',
    paddingTop: '5%',
    paddingLeft: '10%',
    paddingRigth: '10%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingRigth: '0%',
      paddingLeft: '0%',
      paddingTop: '15%',
    },
  },
  media: {
    boxShadow: '-12px -12px #078080',
    outline: '6px solid white',
    objectFit: 'contain',
  },
  img: {
    objectFit: 'contain',
    maxWidth: '100%',
    boxShadow: '-12px -12px #078080',
    outline: '6px solid white',
  },
  text: {
    fontFamily: 'Open Sans',
    lineHeight: 1.8,
    color: '#232323',
    textAlign: 'justify',
  },
  tutorial: {
    fontFamily: 'Open Sans',
    lineHeight: 1.8,
    color: '#232323',
    textAlign: 'justify',
    width: '50%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      paddingRigth: '0%',
      paddingLeft: '0%',
    },
  },
  shortenedParagraph: {
    lineHeight: 1.8,
    color: '#232323',
    textAlign: 'justify',
    fontSize: '1.25em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      paddingBottom: '2%',
    },
  },
  paragraph: {
    color: '#232323',
    lineHeight: 1.8,
    textAlign: 'justify',
    '& li': {
      paddingBottom: '2%',
    },
  },
  textBox: {
    marginTop: '5%',
    marginBottom: '5%',
    borderRadius: '5px',
    background: '#f8f5f2',
    textAlign: 'justify',
    'box-shadow': '0 0 0 30px #f8f5f2',
  },
  fillIn: {
    fontSize: '3em',
    lineHeight: 1.8,
    width: '100%',
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '1.5em',
    },
  },
  button: {
  },
});

function Headings() {
  const classes = useStyles();

  return (
    <div className={classes.flexParagraph}>
      <div className={classes.heading}>
        <div className={classes.title}>
          <FormattedMessage id="Census.Header" />
          <Divider className={classes.headingDivider}></Divider>
        </div>
        <div className={classes.shortenedParagraph}>
          <FormattedMessage id="Census.Description" />
        </div>
      </div>
      <div className={classes.subtitles}>
        <Divider className={classes.subtitlesDivider}></Divider>
        <div className={classes.subtitle}>
          <FormattedMessage id="Census.Heading.Chance" />
        </div>
        <Divider className={classes.subtitlesDivider}></Divider>
        <div className={classes.subtitle}>
          <FormattedMessage id="Census.Heading.Change" />
        </div>
        <Divider className={classes.subtitlesDivider}></Divider>
        <div className={classes.subtitle}>
          <FormattedMessage id="Census.Heading.Memory" />
        </div>
        <Divider className={classes.subtitlesDivider}></Divider>
      </div>
    </div>
  );
}

function CensusParas() {
  const classes = useStyles();

  return (
    <div className={classes.textBox}>
      <div className={classes.paragraph}>
        <FormattedMessage id="Census.Para.Perspective" />
      </div>
      <div className={classes.paragraph}>
        <FormattedMessage id="Census.Para.Changes" />
      </div>
    </div>
  );
}

function CensusHowTo() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.flexReverse}>
        <div className={classes.video}>
          <ReactPlayer
            className={classes.media}
            url="https://www.youtube-nocookie.com/embed/7yz3W8z_Ga8"
          />
        </div>
        <div className={classes.howToHeading}>
          <div className={classes.howTo}>
            <FormattedMessage id="Census.HowTo" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Tutorial() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.flexParagraph}>
        <div className={classes.tutorial}>
          <FormattedMessage id="Census.Tutorial.Description" />
          <FormattedMessage
            id="Census.Tutorial.Methods"
            values={{
              ul: (...msg) => <ul>{msg}</ul>,
              li: (...msg) => <li>{msg}</li>,
            }}
          />
          <FormattedMessage id="Census.Tutorial.Time" />
          <FormattedMessage
            id="Census.Tutorial.Login"
            values={{
              ul: (...msg) => <ul>{msg}</ul>,
              li: (...msg) => <li>{msg}</li>,
            }}
          />
          <FormattedMessage id="Census.Tutorial.Lemkos" />
        </div>
        <div className={classes.picture}>
          <img src={pc} alt="pc" className={classes.img} />
        </div>
      </div>
    </div>
  );
}

function FillIn() {
  const classes = useStyles();

  return (
    <div className={classes.fillIn}>
      <FavoriteBorderIcon fontSize="large" />
      <div className={classes.text}>
        <FormattedMessage id="Common.YourMove" />
      </div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        href="https://nsp2021.spis.gov.pl/"
      >
        <FormattedMessage id="Common.FillIn" />
      </Button>
    </div>
  );
}

function Census() {
  const classes = useStyles();

  return (
    <Subpage>
      <div className={classes.outer}>
        <div className={classes.content}>
          <Headings />
          <CensusParas />
          <CensusHowTo />
          <Tutorial />
          <FillIn />
        </div>
      </div>
    </Subpage>
  );
}

export default Census;
