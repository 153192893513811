import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      flexDirection: 'column',
      marginTop: '0',
    },
  },
  content: {
    padding: '1% 10% 1% 10%',
    height: 'auto',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      padding: '1% 5% 1% 5%',
    },
  },
  header: {
    fontSize: '2em',
    padding: '0 0 2% 0',
  },
  row: {
    flexShrink: '1',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'auto',
  },
  button: {
    marginTop: '2%',
    display: 'block',
    textDecoration: 'none',
  },
  text: {
    display: 'flex',
    fontFamily: 'Open Sans',
    color: '#232323',
    textAlign: 'justify',
    lineHeight: '2em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '100%',
      lineHeight: '1.5em',
    },
  },
  quote: {
    fontSize: '3em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '1.5em',
    },
  },
  author: {
    margin: '5% 5% 5% 5%',
  },
  img: {
    objectFit: 'contain',
    maxWidth: '90%',
  },
  '@keyframes fadeInEffect': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
});

const Lemkos = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>
          <FormattedMessage id="Lemkos.Header" />
        </div>
        <div className={classes.row}>
          <div className={classes.text}>
            <FormatQuoteIcon className={classes.quote} />
            <i>
              <FormattedMessage id="Lemkos.index.text" />
            </i>
            <FormatQuoteIcon className={classes.quote} />
          </div>
          <div className={classes.author}>
            <b>
              <FormattedMessage id="Lemkos.index.author" />
            </b>
          </div>
          <div className={classes.button}>
            <Link to="/lemkos" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary">
                <FormattedMessage id="Common.ReadMore" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lemkos;
