import React from 'react';
import { IntlProvider } from 'react-intl';

import messages_en from '../assets/translations/en.json';
import messages_pl from '../assets/translations/pl.json';
import messages_lem from '../assets/translations/lem.json';

const messages = {
  en: messages_en,
  pl: messages_pl,
  lem: messages_lem,
};

const Context = React.createContext();

class IntlContextProvider extends React.Component {
  constructor(...args) {
    super(...args);
    this.switchToEnglish = () => this.setState({ locale: 'en', messages: messages['en'] });
    this.switchToPolish = () => this.setState({ locale: 'pl', messages: messages['pl'] });
    this.switchToLemko = () => this.setState({ locale: 'lem', messages: messages['lem'] });

    this.state = {
      locale: 'pl',
      messages: messages['pl'],
      switchToEnglish: this.switchToEnglish,
      switchToPolish: this.switchToPolish,
      switchToLemko: this.switchToLemko,
    };
  }
  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="pl">
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}
export { IntlContextProvider, Context as IntlContext };
