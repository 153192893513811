import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Toolbar, Button, IconButton, Drawer, Link, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LanguageBar from './LanguageBar';
import LogoIcon from './LogoIcon';
import LanguageDropdown from './LanguageDropdown';
import pikto from '../assets/photos/pikto-black.png';
import logo from '../assets/photos/logo.png';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#078080',
    },
    secondary: {
      main: '#232323',
    },
  },
});

const headersData = [
  {
    label: <FormattedMessage id="Navbar.Lemkos" />,
    href: '/lemkos',
  },
  {
    label: <FormattedMessage id="Navbar.Census" />,
    href: '/census',
  },
  {
    label: <FormattedMessage id="Navbar.MeetUs" />,
    href: '/meetUs',
  },
  {
    label: <FormattedMessage id="Navbar.LawNote" />,
    href: '/lawNote',
  },
];

const useStyles = makeStyles(() => ({
  nav: {
    position: 'fixed',
    'z-index': 1,
    padding: '1% 10% 3% 10%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    top: '0',
    left: '0',
    width: '100%',
    height: '5%',
    '& button': {
      background: 'rgba(0, 0, 0, 0)',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      userSelect: 'none',
      color: '#232323',
      '&.active': {
        borderBottom: '3px solid rgba(7, 128, 128, 1)',
        animation: '$fadein 1.5s',
      },
    },
  },
  navSolid: {
    position: 'fixed',
    background: '#f8f5f2',
    'z-index': 1,
    padding: '1% 10% 3% 10%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    top: '0',
    left: '0',
    width: '100%',
    height: '5%',
    '& button': {
      background: 'rgba(0, 0, 0, 0)',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      userSelect: 'none',
      color: '#232323',
      '&.active': {
        borderBottom: '3px solid rgba(7, 128, 128, 1)',
        animation: '$fadein 1.5s',
      },
    },
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  item: {
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: '0.25em 0.25em',
    margin: '0',
  },
}));

export default function NavBar() {
  const { nav, navSolid, menuButton, drawerContainer, item } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    transparentNavbar: true,
  });

  const { mobileView, drawerOpen, transparentNavbar } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1200
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    const handleScroll = () => {
      return window.scrollY < 100
        ? setState((prevState) => ({ ...prevState, transparentNavbar: true }))
        : setState((prevState) => ({ ...prevState, transparentNavbar: false }));
    };
    handleScroll();

    window.addEventListener('scroll', () => handleScroll());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={transparentNavbar ? nav : navSolid}>
        {getLogo()}
        <div>{getMenuButtons()}</div>
        <LanguageBar />
      </Toolbar>
    );
  };

  const getLogo = () => {
    return (
      <Button
        {...{
          to: '/',
          component: RouterLink,
          className: menuButton,
        }}
      >
        <LogoIcon src={pikto} srcOnHover={logo} alt="logo" />
      </Button>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={transparentNavbar ? nav : navSolid}>
        <IconButton
          {...{
            edge: 'start',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        {getLogo()}
        <LanguageDropdown />
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            className: item,
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <nav>
        <div> {mobileView ? displayMobile() : displayDesktop()} </div>
      </nav>
    </ThemeProvider>
  );
}
