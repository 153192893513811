import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IntlContext } from '../utils/IntlContextProvider';

const useStyles = makeStyles({
  link: {
    padding: '0.25em 0.25em',
  },
});

const LanguageDropdown = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { switchToEnglish, switchToPolish, switchToLemko } = React.useContext(IntlContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button aria-haspopup="true" onClick={handleClick}>
        <div className={classes.icon}>
          <LanguageIcon color="secondary" />
        </div>
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={switchToLemko} color="red">
          LEM
        </MenuItem>
        <MenuItem onClick={switchToEnglish} color="red">
          EN
        </MenuItem>
        <MenuItem onClick={switchToPolish} color="red">
          PL
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageDropdown;
