import React from 'react';
import { makeStyles } from '@material-ui/core';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

const useStyles = makeStyles({
  date: {
    color: 'black !important',
    'font-size': '1.5em !important',
  },
  main: {
    color: 'grey !important',
  },
});

const TimelineElement = (props) => {
  const classes = useStyles();
  const icon = {
    background: '#ed837d',
    color: '#fff',
    //'box-shadow': '0 0 0 4px #078080, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)'
  };
  const leftShift = {};
  const arrow = { borderRight: '7px solid white' };
  const contentStyle = { background: 'white', color: '#3e393d' };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={contentStyle}
      contentArrowStyle={arrow}
      date={props.date}
      dateClassName={classes.date}
      textClassName={classes.main}
      style={leftShift}
      iconStyle={icon}
      position="right"
    >
      <h3 className="vertical-timeline-element-title">{props.children}</h3>
    </VerticalTimelineElement>
  );
};

export default TimelineElement;
