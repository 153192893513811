import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { register } from './serviceWorker';
import { IntlContextProvider } from './utils/IntlContextProvider';
import ScrollToTop from './components/ScrollToTop';
import './styles/index.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/navigation/navigation.scss';

ReactDOM.render(
  <Router>
    <IntlContextProvider>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </IntlContextProvider>
  </Router>,
  document.getElementById('root'),
);

register();
