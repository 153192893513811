import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Home from './Home';
import Lemkos from './Lemkos';
import Census from './Census';
import Kids from './Kids';
import FillIn from './FillIn';
import Subpage from '../components/Subpage';

const useStyles = makeStyles({
  section: {
    display: 'flex',
    height: 'auto',
    minHeight: '100vh',
    overflow: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f8f5f2',
    position: 'relative',
  },
  img: {
    display: 'flex',
    width: '100%',
    maxHeight: '100vh',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f8f5f2',
  },
  fillIn: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fffffe',
  },
  wrapper: {
    position: 'static',
    height: '100%',
    width: '100%',
  },
  '@keyframes fadein': {
    '0%': {
      borderBottom: '3px solid rgba(7, 128, 128, 0)',
    },
    '100%': {
      borderBottom: '3px solid rgba(7, 128, 128, 1)',
    },
  },
});

const App = () => {
  const classes = useStyles();

  return (
    <Subpage>
      <div className={classes.wrapper}>
        <section className={classes.img}>
          <Home />
        </section>
        <section className={classes.section}>
          <Lemkos />
        </section>
        <section className={classes.img}>
          <Kids />
        </section>
        <section className={classes.section}>
          <Census />
        </section>
        <section className={classes.fillIn}>
          <FillIn />
        </section>
      </div>
    </Subpage>
  );
};

export default App;
