import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import pikto from '../assets/photos/pikto-green.png';

const useStyles = makeStyles({
  wrapper: {
    height: '10%',
    overflowY: 'auto',
    backgroundColor: '#f8f5f2',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1% 10% 1% 10%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1920px)']: {
      'max-width': '1600px',
      margin: 'auto',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      fontSize: '0.5em',
    },
  },
  footerContainer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#078080',
    width: '100%',
    paddingBottom: '1%',
    paddingTop: '1%',
  },
  copyrightContainer: {},
  socialMediasContainer: {
    alignItems: 'center',
    alignContent: 'center',
    paddingLeft: '16em',
  },
  singleMedia: {
    fontSize: '2em',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  copyright: {
    textAlign: 'right',
  },
  logoContainer: {
    width: 'inline-block',
  },
  logo: {
    maxHeight: '36px',
  },
  img: {
    maxHeight: '25px',
  },
  icon: {
    color: '#078080',
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.footerContainer}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <img src={pikto} alt="logo" className={classes.logo} />
            </div>
          </div>
          <div className={classes.socialMediasContainer}>
            <a className={classes.singleMedia} href="https://www.facebook.com/jalemko">
              <FontAwesomeIcon className={classes.icon} icon={faFacebookSquare} />
            </a>
            <a className={classes.singleMedia} href="https://www.instagram.com/ja.lemko/">
              <FontAwesomeIcon className={classes.icon} icon={faInstagram} />
            </a>
          </div>
          <div className={classes.copyrightContainer}>
            <div className={classes.copyright}>
              Copyright &copy; 2020 JA LEMKO. All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
