import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';

const Subpage = (props) => {
  return (
    <div>
      <NavBar />
      {props.children}
      <Footer />
    </div>
  );
};

export default Subpage;
