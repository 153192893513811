import React from 'react';
import { Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import App from './pages/App';
import MeetUs from './pages/MeetUs';
import Lemkos from './pages/Lemkos/Lemkos';
import Census from './pages/Census/Census';
import LawNote from './pages/LawNote';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#078080',
    },
    secondary: {
      main: '#232323',
    },
  },
});

export const Routes = () => {
  return (
    <ThemeProvider theme={theme}>
      <Route exact path="/" component={App} />
      <Route exact path="/meetUs" component={MeetUs} />
      <Route exact path="/lemkos" component={Lemkos} />
      <Route exact path="/census" component={Census} />
      <Route exact path="/lawNote" component={LawNote} />
    </ThemeProvider>
  );
};

export default Routes;
