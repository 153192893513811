import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';
import { carouselCards } from './carouselCards';

SwiperCore.use([EffectCoverflow, Navigation]);

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const useStyles = makeStyles({
  content: {
    height: 'auto',
    minHeight: '80vh',
    'z-index': 0,
    margin: '1% 5% 1% 5%',
  },
  img: {
    objectFit: 'contain',
    width: '10vw',
    margin: '16px auto',
    borderRadius: '50%',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1050px), (max-height: 640px)']: {
      width: '20vw',
    },
  },
  card: {
    minHeight: '80vh',
    fontSize: '0.875em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:1381px)']: {
      minHeight: '70vh',
    },
  },
  quote: {
    padding: '5% 5% 5% 5%',
    fontStyle: 'italic',
    textAlign: 'justify',
    fontSize: '1.25em',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1380px)']: {
      fontSize: '1em',
    },
  },
  name: {
    textTransform: 'uppercase',
    color: '#078080',
  },
  subName: {
    margin: '4px',
  },
});

const shuffledCards = shuffleArray(carouselCards);

const Carousel = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  return (
    <div className={classes.content}>
      <Swiper
        loop
        effect="coverflow"
        navigation
        slidesPerView={mobileView ? '1' : '3'}
        centeredSlides
        coverflowEffect={{
          rotate: 0, // Slide rotate in degrees
          stretch: -10, // Stretch space between slides (in px)
          depth: 0, // Depth offset in px (slides translate in Z axis)
          //modifier: 1, // Effect multipler
          slideShadows: false, // Enables slides shadows
        }}
      >
        {shuffledCards.map((carouselCard, index) => (
          <SwiperSlide key={index} index={index} style={{ backgroundColor: 'white' }}>
            <div className={classes.card} style={{ margin: '16px', height: '99%' }}>
              <img src={carouselCard.img} alt={carouselCard.alt} className={classes.img} />
              <p className={classes.quote}>{intl.formatMessage(carouselCard.quote)}</p>
              <p className={classes.name}>{intl.formatMessage(carouselCard.name)}</p>
              <p className={classes.subName}>{intl.formatMessage(carouselCard.subName)}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default Carousel;
