import React from 'react';

interface IconProps {
  src?: string;
  srcOnHover?: string;
  alt?: string;
}

const LogoIcon: React.FC<IconProps> = ({ src, srcOnHover, alt }) => (
  <img
    src={src}
    alt={alt}
    style={{ height: '36px' }}
    onMouseOver={(e): void => {
      srcOnHover && (e.currentTarget.src = srcOnHover);
    }}
    onMouseOut={(e): void => {
      srcOnHover && (e.currentTarget.src = src || '');
    }}
  />
);

export default LogoIcon;
